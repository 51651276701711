import { createWebHistory, createRouter } from "vue-router";
import Home from "./apps/Home.vue";
//import Home from "./apps/HomeAux.vue";
import IndexProductos from "./apps/IndexProductos.vue";
import Login from "./apps/Login.vue";
import Registro from "./apps/Registro.vue";
import Catalogo from "./apps/Catalogo.vue";
import IndexColaciones from "./apps/IndexColaciones.vue";
import Perfil from "./apps/Perfil.vue";
import AdmCompras from "./apps/AdmCompras.vue";
import Contacto from "./apps/Contacto.vue";
import ConfirmarPedido from "./apps/ConfirmarPedido.vue";
import Compras from "./apps/Compras.vue";
const routes = [
    {
        path: "/compras",
        component: Compras,
    },
    {
        path: "/confirmar_pedido",
        component: ConfirmarPedido,
    },
    {
        path: "/contacto",
        component: Contacto,
    },
    {
        path: "/adm_compras",
        component: AdmCompras,
    },
    {
        path: "/perfil",
        component: Perfil,
    },
    {
        path: "/index_colaciones",
        component: IndexColaciones,
    },
    {
        path: "",
        component:Home,
    },
    {
        path: "/index_productos",
        component: IndexProductos,
    },
    {
        path: "/login",
        component: Login,
    },
    {
        path: "/catalogo",
        component: Catalogo,
    },
    {
        path: "/registro",
        component: Registro,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
});
  
export default router;