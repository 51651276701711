<template>
    <NavBar :user="user"></NavBar>
    <div class="flex flex-col space-y-5 w-[80%] mx-auto">
        <div class="mt-10">
            <a href="/">
                <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    Volver a la pagina principal
                </button>
            </a>
        </div>
        <div>
            <h1 class="text-2xl font-bold text-center">Detalle</h1>
            <div class="relative overflow-x-auto">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                ID
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Fecha
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Total
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Estado
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Productos
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="c in compras" :key="c" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                            <td class="px-6 py-4">
                                {{ c.id }}
                            </td>
                            <td class="px-6 py-4">
                                {{ c.fecha }}
                            </td>
                            <td class="px-6 py-4">
                                {{ formatCurrency(c.total) }}
                            </td>
                            <td>
                                {{ c.estado }}
                            </td>
                            <td>
                                <div class="flex flex-row">
                                    <div class="px-6 py-4">
                                        Nombre
                                    </div>
                                    <div class="px-6 py-4">
                                        Cantidad
                                    </div>
                                    <div class="px-6 py-4">
                                        Precio
                                    </div>
                                    <div class="px-6 py-4">
                                        Total
                                    </div>
                                </div>
                                <div class="flex flex-row" v-for="d in c.detalles" :key="d">
                                    <div class="px-6 py-4">
                                        {{ d.producto }}
                                    </div>
                                    <div class="px-6 py-4">
                                        {{ d.cantidad }}
                                    </div>
                                    <div class="px-6 py-4">
                                        {{ formatCurrency(d.precio) }}
                                    </div>
                                    <div class="px-6 py-4">
                                        {{ formatCurrency(d.subtotal) }}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import NavBar from '@/components/Navbar.vue'
export default {
    components: {
        NavBar
    },
    name: "ComprasInfo",
    el: "#app",
    props: {
      ip_prop: String,
    },
    data() {
        return {
            compras: [],
            admin:false,
            user:null
        }
    },
    mounted(){
        window.select_funcion = this.select_funcion;
        window.load_axios('chequeo_sesion',2);
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const id = urlParams.get('id');
        if (id) {
            let form_data = new FormData();
            form_data.append('id', id);
            window.load_response('/cargar_compras_id',form_data,1);
        }else{
            window.load_axios('/cargar_compras',1);
        }
    },
    methods:{
        formatCurrency(value) {
            return new Intl.NumberFormat('es-CL', {
                style: 'currency',
                currency: 'CLP'
            }).format(value);
        },
        select_funcion(result, numero_funcion) {
            switch(numero_funcion){
                case 1:
                    this.compras = result.data.compras;
                    break;
                case 2:
                    if(result.data['user']==null){
                        window.location.href = '/login';
                    }
                    if(result.data['admin']){
                        this.admin = true;
                    }
                    this.user = result.data['user'];
                    break;
            }
        }
    }
}
</script>

