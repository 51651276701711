<template>
    <NavBar :user="user"></NavBar>
    <div class="background-catalogo border-t-2 border-gray-300">
        <div class="flex flex-col space-y-5 w-[80%] mx-auto h-[1000px] text-white">
            <!-- <div class="mt-10">
                <a href="/">
                    <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        Volver a la pagina principal
                    </button>
                </a>
            </div> -->
            <div v-if="admin" class="mt-10 flex flex-row space-x-2">
                <a href="/index_productos">
                    <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        Index productos
                    </button>
                </a>
                <a href="/index_colaciones">
                    <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        Index colaciones
                    </button>
                </a>
            </div>
            <div>
                <h1 class="text-4xl font-bold text-center my-10 bg-[#000000e3] border rounded w-[30%] mx-auto">Compras</h1>
                <div class="relative overflow-x-auto">
                    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    ID
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Fecha
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Total
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Estado
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Detalles
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="c in compras" :key="c" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {{ c.id }}
                                </th>
                                <td class="px-6 py-4">
                                    {{ c.fecha }}
                                </td>
                                <td class="px-6 py-4">
                                    {{ c.total }}
                                </td>
                                <td class="px-6 py-4">
                                    {{ c.estado }}
                                </td>
                                <td class="px-6 py-4">
                                    <button @click="verDetalle(c.id)" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        Detalles
                                    </button>
                                    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                        <tr :id="'detalle_'+c.id" v-for="d in c.detalles" :key="d" class="hidden">
                                            <td class="px-6 py-4">
                                                {{ d.producto }}
                                            </td>
                                            <td class="px-6 py-4">
                                                {{ d.cantidad }}
                                            </td>
                                            <td class="px-6 py-4">
                                                {{ d.precio }}
                                            </td>
                                            <td class="px-6 py-4">
                                                {{ d.subtotal }}
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    
</template>
<script>
//import Swal from 'sweetalert2/dist/sweetalert2.js'
import logoInicio from "@/assets/inicio_logo.png";
import NavBar from '@/components/Navbar.vue'
export default {
    name: "PerfilUsuario",
    el: "#app",
    components:{NavBar},
    props: {
      ip_prop: String,
    },
    data() {
        return {
            compras: [],
            logoInicio: logoInicio,
            admin:false,
            user:null
        }
    },
    mounted(){
        window.select_funcion = this.select_funcion;
        window.load_axios('get_compras',1);
        window.load_axios('chequeo_sesion',2);

    },
    methods:{
        verDetalle(id){
            let detalle = document.getElementById('detalle_'+id);
            if(detalle.classList.contains('hidden')){
                detalle.classList.remove('hidden');
            }else{
                detalle.classList.add('hidden');
            }
        },
        select_funcion(result, numero_funcion) {
            switch(numero_funcion){
                case 1:
                    this.compras = result.data['compras'];
                    this.admin = result.data['admin'];
                    break;
                case 2:
                    if(result.data['user']==null){
                        window.location.href = '/login';
                    }
                    if(result.data['admin']){
                        this.admin = true;
                    }
                    this.user = result.data['user'];
                    break;
            }
        }
    }
}
</script>
<style>
.background-catalogo {
  background-image: url('@/assets/fondoCatalogo.jpg');
  background-size: 100% auto; /* Ajusta el ancho al 100% y la altura automáticamente */
  background-position: center; /* Centra la imagen */
  background-repeat: no-repeat;
  filter: brightness(0.9);
}
</style>
