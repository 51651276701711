<template>
    <NavBar :user="user"></NavBar>
    <div class="flex flex-col space-y-5 w-[80%] mx-auto">
        <div class="mt-10">
            <a href="/">
                <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    Volver a la pagina principal
                </button>
            </a>
        </div>
        <div>
            <label for="correo" class="text-lg font-bold">Ingrese su correo</label>
            <input type="text" id="correo" v-model="correo" class="w-full px-3 py-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" placeholder="Correo">
            <h1 class="text-2xl font-bold text-center">Detalle</h1>
            <div class="relative overflow-x-auto">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                Producto
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Cantidad
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Total
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Acciones
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="c in cartItems" :key="c" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                            <td class="px-6 py-4">
                                {{ c.name }}
                            </td>
                            <td class="px-6 py-4">
                                {{ c.quantity }}
                            </td>
                            <td class="px-6 py-4">
                                {{ formatCurrency(c.quantity * c.price) }}
                            </td>
                            <td>
                                <button @click="removeFromCart(c)" class="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700">Eliminar</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- Total -->
            <div class="flex flex-row justify-between items-center mt-4">
                <div class="text-lg font-bold">Total</div>
                <div class="text-lg font-bold">{{ formatCurrency(cartItems.reduce((total, item) => total + item.price * item.quantity, 0)) }}</div>
            </div>
            <button v-if="!disabled" @click="doOrder" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Realizar compra
            </button>
            <button v-else type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Realizando compra...
            </button>
        </div>
    </div>
</template>
<script>
import Swal from "sweetalert2";
import NavBar from "@/components/Navbar.vue";
export default {
    components: { NavBar },
    name: "ConfirmarPedido",
    el: "#app",
    props: {
      ip_prop: String,
    },
    data() {
        return {
            correo: '',
            cartItems: [],
            disabled: false,
            user: null,
            admin: false
        }
    },
    mounted(){
        window.select_funcion = this.select_funcion;
        window.load_axios('chequeo_sesion',2);
        this.loadCart();
    },
    methods:{
        doOrder(){
            if(this.disabled){
                return;
            }
            if(this.correo == ''){
                Swal.fire({
                    title: 'Error',
                    text: 'Ingrese un correo',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
                return;
            }
            let form_data = new FormData();
            form_data.append('correo', this.correo);
            form_data.append('productos', JSON.stringify(this.cartItems));
            form_data.append('total', this.cartItems.reduce((total, item) => total + item.price * item.quantity, 0));
            window.load_response('/do_order',form_data,1);
        },
        removeFromCart(product) {
            const index = this.cartItems.findIndex(item => item.id === product.id);
            if (index !== -1) {
                if (this.cartItems[index].quantity > 1) {
                this.cartItems[index].quantity -= 1;
                } else {
                this.cartItems.splice(index, 1);
                }
            }
            this.saveCart();
        },
        saveCart() {
            localStorage.setItem('cartItems', JSON.stringify(this.cartItems));
        },
        formatCurrency(value) {
            return new Intl.NumberFormat('es-CL', {
                style: 'currency',
                currency: 'CLP'
            }).format(value);
        },
        loadCart() {
            const savedCart = localStorage.getItem('cartItems');
            if (savedCart) {
                this.cartItems = JSON.parse(savedCart);
            }
        },
        select_funcion(result, numero_funcion) {
            switch(numero_funcion){
                case 1:
                    Swal.fire({
                        title: 'Compra realizada',
                        text: 'Se ha realizado la compra con exito, la orden fue enviada a su correo.',
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    }).then(() => {
                        localStorage.removeItem('cartItems');
                        this.cartItems = [];
                        window.location.href = '/';
                    });
                    this.disabled = false;
                    break;
                case 2:
                    if(result.data['user']==null){
                        window.location.href = '/login';
                    }
                    if(result.data['admin']){
                        this.admin = true;
                    }
                    this.user = result.data['user'];
                    break;
            }
        }
    }
}
</script>

