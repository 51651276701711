<template>
    <NavBar :user="user"></NavBar>
    <div class="flex flex-col pt-5 background-catalogo border-t-2 border-gray-300 text-white">
        <!-- <div class="flex flex-row justify-between items-center">
            <a href="/" class="ml-10">
                <button class="bg-red-500 text-white px-4 py-2 rounded-lg flex flex-row">
                    <ArrowLongLeftIcon class="w-[25px] mr-3"></ArrowLongLeftIcon>Regresar
                </button>
            </a>
        </div> -->
        <h1 v-if="!categoria" class="text-4xl font-bold mb-4 text-black">Catalogo</h1>
        <div class="p-4">
            <div class="mb-8">
                <div class="text-4xl bg-white text-black w-[300px] my-6 p-4 shadow-2xl -rotate-[2deg] mx-auto border-2 border-black">{{categoria}}</div>
                <div class="w-[500px] mx-auto">
                    <input 
                        v-model="filtroProducto" 
                        placeholder="Buscar producto" 
                        class="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                </div>
                <div class="overflow-y-auto h-[800px]">
                    <div class="flex flex-wrap gap-[30%] items-center justify-center">
                        <div class="relative rounded-lg overflow-hidden p-4 w-[500px]" 
                            v-for="producto,index in productosFiltrados" 
                            :key="index">
                            <div class="flex flex-col mt-16 cursor-pointer items-center" @click="handleAddToCart({id:producto.id,name:producto.titulo,price:producto.precio})">
                                <h3 class="text-xl font-semibold mb-2 bg-[#000000e3] border rounded p-2">{{ producto.titulo }}</h3>
                                <div class="bg-white -rotate-[3deg] shadow-2xl border-2 border-black w-[250px]">
                                    <img v-if="producto.imagen" :src="'static/productos/'+producto.imagen" alt="Imagen del producto" class="w-[250px] h-[220px] object-cover mb-4" />
                                    <img v-else :src="'static/productos/producto.png'" alt="Imagen del producto" class="w-[200px] h-[200px] object-cover mb-4" />
                                    <div class="absolute bottom-2 right-2 bg-red-500 text-white font-bold px-2 py-1 rounded-full price-tag text-4xl">
                                        {{ producto.precio }}
                                    </div>
                                </div>
                                <h3 v-if="producto.descripcion" class="text-xl mt-4 bg-[#000000e3] border rounded p-1">{{ producto.descripcion }}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  <script>
  import logoInicio from "@/assets/inicio_logo.png";
  import NavBar from '@/components/Navbar.vue'
  export default {
    components: {
      NavBar
    },
    name: 'CatalogoAko',
    data() {
      return {
        productos: [],
        categoria: '',
        logoInicio: logoInicio,
        user:null,
        admin:false,
        filtroProducto: ''
      };
    },
    computed: {
        productosFiltrados(){
            let productosFiltrados = [];
            if(this.filtroProducto==''){
                return this.productos;
            }
            for (let pid in this.productos) {
                let producto = this.productos[pid];
                let titulo = producto.titulo.toLowerCase().includes(this.filtroProducto.toLowerCase());
                if (titulo) {
                    productosFiltrados.push(producto);
                }
            }
            return productosFiltrados;
        },
    },
    mounted() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.categoria = urlParams.get('cat');
        let form_data = new FormData();
        form_data.append('categoria', this.categoria);
        window.load_response('/get_productos_categoria',form_data,1);
        window.select_funcion = this.select_funcion;
        window.load_axios('chequeo_sesion',2);
    },
    methods: {
        handleAddToCart(product) {
            window.addToCart(product);
        },
        select_funcion(response, numero_funcion){
            switch(numero_funcion){
                case 1:
                    this.productos = response.data.productos;
                    break;
                case 2:
                    this.user = response.data.user;
                    this.admin = response.data.admin;
                    break;
            }

        }
    }
  }
  </script>
<style>
.background-catalogo {
  background-image: url('@/assets/fondoCatalogo.jpg');
  background-size: 100% auto; /* Ajusta el ancho al 100% y la altura automáticamente */
  background-position: center; /* Centra la imagen */
  background-repeat: no-repeat;
  filter: brightness(0.9);
}
</style>