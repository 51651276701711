<template>
    <div>
      <div class="cart-button z-50" @click="toggleCart">
        🛒 ({{ cartItems.length }})
      </div>
      <div v-if="isCartVisible" class="cart-popup z-50">
        <ShoppingCart :cartItems="cartItems" @remove-from-cart="removeFromCart" />
        <div class="text-center my-4">
          Total: {{ formatCurrency(cartItems.reduce((total, item) => total + item.price * item.quantity, 0)) }}
        </div>
        <button v-if="cartItems.length != 0" @click="createPurchase" class="w-[200px] h-[50px] bg-cyan-400 text-white text-[20px] rounded-xl">Comprar</button>
      </div>
    </div>
  </template>
  
  <script>
  import ShoppingCart from './ShoppingCart.vue';
  
  export default {
    components: { ShoppingCart },
    props: ['cartItems'],
    data() {
      return {
        isCartVisible: false,
      };
    },
    methods: {
      createPurchase(){
        window.location.href = "/confirmar_pedido";
      },
      toggleCart() {
        this.isCartVisible = !this.isCartVisible;
      },
      removeFromCart(product) {
        this.$emit('remove-from-cart', product);
      },
      formatCurrency(value) {
        return new Intl.NumberFormat('es-CL', {
          style: 'currency',
          currency: 'CLP'
        }).format(value);
      }
    }
  }
  </script>
  
  <style scoped>
  .cart-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #ff6600;
    color: white;
    padding: 10px 15px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .cart-popup {
    position: fixed;
    bottom: 80px;
    right: 20px;
    width: 500px;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    padding: 15px;
    border-radius: 10px;
  }
  </style>
  