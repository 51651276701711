<template>
    <NavBar></NavBar>
    <div class="flex flex-col space-y-5 w-[80%] mx-auto mt-10">
        <div>
            <label for="input_email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Correo</label>
            <input v-model="email" type="text" id="input_email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="correo electronico" required />
        </div>
        <div>
            <label for="input_password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Contraseña</label>
            <input @keyup.enter="iniciar_sesion" v-model="password" type="password" id="input_password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="contraseña" required />
        </div>
        <button @click="iniciar_sesion" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Iniciar sesión</button>
        <div>
            <a href="/registro" class="text-blue-700 hover:text-blue-800">Registrate</a>
        </div>
    </div>
</template>
<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import NavBar from '@/components/Navbar.vue'
export default {
    name: "LoginConfitel",
    el: "#app",
    components:{NavBar},
    props: {
      ip_prop: String,
    },
    data() {
        return {
            email:'',
            password:''
        }
    },
    mounted(){
        window.select_funcion = this.select_funcion;
        window.load_axios('check_sesion',2);
    },
    methods:{
        iniciar_sesion(){
            let formData = new FormData();
            formData.append('email', this.email);
            formData.append('password', this.password);
            window.load_response("iniciar_sesion", formData, 1);
        },
        select_funcion(result, numero_funcion) {
            switch(numero_funcion){
                case 1:
                    if(result.data['status']==200){
                        location.href='/';
                    }else{
                        Swal.fire({
                            title: 'Error!',
                            text: result.data['error'],
                            icon: 'error',
                            confirmButtonText: 'Aceptar'
                        })
                    }
                    break;
                case 2:
                    if(result.data['status']==200){
                        location.href='/perfil';
                    }
                    break;
            }
        }
    }
}
</script>

