<template>
    <div class="flex flex-col bg-white">
        <div class="font-bold text-[40px] mt-4">
            FAMILIAS DE PRODUCTOS
        </div>
        <div class="px-10 py-5">
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-10 justify-center">
                
                <a :href="'/catalogo?cat='+c" v-for="(c, index) in categorias.slice(0, 5)" :key="index" class="flex flex-col items-center">
                    <div class="font-bold text-lg md:text-xl">
                        {{ c }}
                    </div>
                    <img :src="imgCat[index]" :alt="'categoria'" class="mb-4 w-auto h-[150px] object-cover"/>
                </a>
            </div>
        </div>
        <div class="px-10 py-5">
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-10 justify-center">
                <a :href="'/catalogo?cat='+c" v-for="(c, index) in categorias.slice(5, 10)" :key="index" class="flex flex-col items-center">
                    <div class="font-bold text-lg md:text-xl">
                        {{ c }}
                    </div>
                    <img :src="imgCat[index+5]" :alt="'categoria'" class="mb-4 w-auto h-[150px] object-cover"/>
                </a>
            </div>
        </div>
        <div class="px-10 py-5">
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-10 justify-center">
                <a :href="'/catalogo?cat='+c" v-for="(c, index) in categorias.slice(10, 15)" :key="index" class="flex flex-col items-center">
                    <div class="font-bold text-lg md:text-xl">
                        {{ c }}
                    </div>
                    <img :src="imgCat[index+10]" :alt="'categoria'" class="mb-4 w-auto h-[150px] object-cover"/>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import chocolates from "@/assets/familias/1Chocolates.png";
import galletas from "@/assets/familias/2GalletasyObleas.png";
import pastillas from "@/assets/familias/3Pastillas.png";
import chupetes from "@/assets/familias/4Chupetes.png";
import chicles from "@/assets/familias/5Chicles.png";
import masticables from "@/assets/familias/6Masticables.png";
import gomitas from "@/assets/familias/7Gomitas.png";
import malvas from "@/assets/familias/8Malvasysustancias.png";
import helados from "@/assets/familias/9Helados.png";
import gaseosa from "@/assets/familias/10BebidasGaseosas.png";
import snacks from "@/assets/familias/11Snacks.png";
import sorpresas from "@/assets/familias/12Sorpresa.png";
import otros from "@/assets/familias/13Otros.png";
export default {
    name: "Pagina4Home",
    data() {
        return {
            categorias:['CHOCOLATES','GALLETAS Y OBLEAS','PASTILLAS','CHUPETES','CHICLES',
                'MASTICABLES','GOMITAS','MALVAS Y SUSTEANCIAS','HELADOS EN CAJAS','GASEOSA Y JUGUITOS',
                'SNACKS','SORPRESAS','OTROS'],
            imgCat:[
                chocolates,galletas,pastillas,chupetes,chicles,masticables,gomitas,malvas,helados,gaseosa,snacks,sorpresas,otros
            ],
        };
    },
}
</script>