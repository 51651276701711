<template>
    <div id="inicio" class="flex flex-col justify-end px-4 md:px-0 background-image">
        <div class="/*fixed top-0 w-full z-50*/ flex flex-row h-[150px] bg-[#0000006a] text-white text-[32px] justify-between p-4">
            <div class="w-[5%]"></div>
            <div class="flex items-center">
                <img :src="logoInicio" alt="Confitel" class="h-[54px] w-[208px]"/>
            </div>
            <div class="hidden md:flex flex-grow items-center justify-around">
                <p class="cursor-pointer">
                    <a href="/">
                        Inicio
                    </a>
                </p>
                <p @click="scrollToSection('catalogo')" class="cursor-pointer">Productos</p>
                <p>
                    <a href="/contacto">
                        Contacto
                    </a>
                </p>
                <p v-if="user">
                    <a href="/perfil">
                        Perfil
                    </a>
                </p>
                <p v-else>
                    <a href="/login">
                        Ingreso/Registro
                    </a>
                </p>
                <p v-if="user">
                    <a href="/logout">
                        Cerrar sesion
                    </a>
                </p>
                <div class="flex items-center">
                    <input type="text" class="focus-visible:border-0 w-[250px] h-[50px] text-[24px] text-center shadow-2xl bg-[#ffc400] text-white placeholder-white" placeholder="Buscar..."/>
                    <div class="flex w-[70px] h-[50px] bg-[#e60000] shadow-2xl items-center justify-center">
                        <MagnifyingGlassIcon class="w-[30px]"></MagnifyingGlassIcon>
                    </div>
                </div>
            </div>
            <div class="md:hidden flex items-center">
                <button @click="toggleMenu" class="text-white">
                    <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
                    </svg>
                </button>
            </div>
        </div>
        <div v-if="menuOpen" class="md:hidden flex flex-col items-center bg-[#0000006a] text-white text-[24px]">
            <p class="py-2">Inicio</p>
            <p class="py-2">Productos</p>
            <p class="py-2">
                <a href="/contacto">
                    Contacto
                </a>
            </p>
            <p class="py-2">
                <a href="/login">
                    Ingreso/Registro
                </a>
            </p>
            <div class="flex flex-col items-center py-2">
                <input type="text" class="focus-visible:border-0 w-[250px] h-[50px] text-[24px] text-center shadow-2xl bg-[#ffc400] text-white placeholder-white" placeholder="Buscar..."/>
                <div class="flex w-[70px] h-[50px] bg-[#e60000] shadow-2xl items-center justify-center mt-2">
                    <MagnifyingGlassIcon class="w-[30px]"></MagnifyingGlassIcon>
                </div>
            </div>
        </div>
        <div v-if="home" class="flex flex-col text-white w-full text-center ml-[30%] mt-20 text-[#70afe6]">
            <div class="text-[40px] md:text-[70px] py-2 font-bold mt-14 leading-tight md:leading-[70px] w-[1000px] bg-[#000000e3] border rounded">
                Bienvenidos a la esquina<br>
                más dulce de Calama
            </div>
            <div class="text-[20px] md:text-[30px] py-2 leading-[25px] md:leading-[35px] mt-6 ml-0 md:ml-6 w-[800px] bg-[#000000e3] border rounded">
                Tu paraíso de golosinas donde encontrarás una amplia<br>
                variedad de caramelos, chocolates y malvas irresistibles.<br>
                ¡Endulza tu día con nosotros!
            </div>
            <div class="flex flex-col md:flex-row ml-0 md:ml-6 space-y-4 md:space-y-0 md:space-x-6 mt-6 w-[800px]">
                <div>
                    <button @click="redirectContacto()" class="w-full md:w-[200px] h-[50px] md:h-[70px] bg-[#e60000] text-white text-[20px] md:text-[24px] rounded-lg">Contáctanos</button>
                </div>
                <div>
                    <button @click="scrollToSection('servicios')" class="w-full md:w-[200px] h-[50px] md:h-[70px] bg-transparent text-white text-[20px] md:text-[24px] border-white border-2 rounded-lg">Servicios</button>
                </div>
            </div>
        </div>
        <div v-if="home" class="py-10"></div>
    </div>
</template>
<script>
import logoInicio from "@/assets/inicio_logo.png";
import { MagnifyingGlassIcon} from '@heroicons/vue/24/solid';
export default {
    components: {
        MagnifyingGlassIcon,
    },
    name: "NavBar",
    props: {
        user: Object,
        home: Boolean,
    },
    data() {
        return {
            menuOpen: false,
            logoInicio: logoInicio,
        };
    },
    methods: {
        toggleMenu() {
            this.menuOpen = !this.menuOpen;
        },
        scrollToSection(section) {
            if(document.getElementById(section)){
                document.getElementById(section).scrollIntoView({ behavior: "smooth" });
            }else{
                location.href='/';
            }
        },
    },
}
</script>