<template>
    <NavBar :user="user" :home="true"></NavBar>
    <div class="/*bg-white*/ flex flex-col">
        <div class="flex flex-col md:flex-row space-x-0 md:space-x-20 justify-center mt-10">
            <div class="text-center md:text-start">
                <div class="font-bold text-[24px] md:text-[40px] mx-4">
                    ¡Ofertas, promociones <br>
                    y Descuentazos! <br>
                    No los dejes pasar
                </div>
                <div class="ml-0 md:ml-10 text-[16px] md:text-[20px] mx-4">
                    ¡No te pierdas nuestras increíbles ofertas!<br>
                    Descubre las mejores promociones y <br>
                    de productos.<br>
                    ¡Compra ahora y ahorra más!
                </div>
                <a href="https://wa.link/qqm57y">
                    <div class="ml-0 md:ml-10 mt-5 flex flex-row justify-center md:justify-start space-x-4">
                        <button class="w-3/4 md:w-[180px] h-[40px] md:h-[50px] bg-[#00b726] text-white text-[16px] md:text-[20px] rounded-lg">Ver ofertas</button>
                        <img :src="wsp" alt="wsp" class="w-[50px] md:w-[62px] h-[56px] md:h-[68px]"/>
                    </div>
                </a>
            </div>
            <div class="flex justify-center mt-10 md:mt-0">
                <img :src="ofertas" alt="Ofertas" class="w-[300px] md:w-[500px] h-[300px] md:h-[500px]"/>
            </div>
        </div>
        <div class="mt-10 px-5">
            <swiper
                class="swiper"
                :modules="modules"
                :space-between="30"
                :slides-per-view="3"
                :autoplay="{ delay: 2500,disableOnInteraction: false }"
                :pagination="{ clickable: true }"
                :breakpoints="{
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 10
                    },
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    768: { 
                        slidesPerView: 3,
                        spaceBetween: 30
                    },
                    1024: { 
                        slidesPerView: 4,
                        spaceBetween: 40
                    },
                }"
            >
                <swiper-slide @click="handleAddToCart({id:product.id,name:product.titulo,price:product.precio,img:product.imagen})" v-for="(product, index) in products" :key="index" class="slide cursor-pointer">
                    <div class="bg-white p-4 h-[350px]">
                        <img :src="'static/productos/'+product.imagen" :alt="product.titulo" class="mx-auto mb-4 w-[200px] h-[200px]"/>
                        <h3 class="text-xl font-bold mb-2">{{ product.titulo }}</h3>
                        <p class="text-gray-700">{{ product.descripcion }}</p>
                        <p v-if="product.precio">{{ formatCurrency(product.precio) }}</p>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
    </div>
    <div class="p-8 text-center background-home">
        <h2 class="text-white text-4xl mx-auto font-bold mb-4 bg-[#000000e3] border rounded w-[800px]">Incorporación Reciente</h2>
        <p class="text-white text-lg mx-auto mb-8 bg-[#000000e3] border rounded w-[600px]">
            Nos complace anunciar la llegada de nuestras nuevas <br>
            incorporaciones, cuidadosamente seleccionadas para ofrecerte<br>
            la mejor calidad y rendimiento. Estos productos han sido diseñados<br>
            para satisfacer las expectativas más exigentes,<br>
            combinando innovación, durabilidad y un diseño excepcional.
        </p>
        <div class="flex my-10 items-center">
            <swiper
                class="swiper w-full items-center justify-center"
                :modules="modules"
                :centeredSlides="true"
                :space-between="30"
                :slides-per-view="3"
                :autoplay="{ delay: 2500,disableOnInteraction: false }"
                :pagination="{ clickable: true }"
                :breakpoints="{
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 10
                    },
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    768: { 
                        slidesPerView: 3,
                        spaceBetween: 30
                    },
                    1024: { 
                        slidesPerView: 4,
                        spaceBetween: 40
                    },
                }"
            >
                <swiper-slide @click="handleAddToCart({id:product.id,name:product.titulo,price:product.precio,img:product.imagen})" v-for="(product, index) in productsIncorporacion" :key="index" 
                class="slide z-10 cursor-pointer">
                    <div class="bg-white p-4 h-[250px] rounded-lg">
                        <img :src="'static/productos/'+product.imagen" :alt="product.titulo" 
                        class="mx-auto mb-4 w-[200px] h-[200px]"/>
                    </div>
                    <div class="my-6 text-white">
                        <div class="text-xl font-bold mb-2 bg-[#000000e3] border">
                            <span class="text-2xl">{{ product.titulo }}</span><br>
                        </div>
                        <div class="text-xl font-bold mb-2 bg-[#000000e3] border">
                            {{ product.descripcion }}<br>
                            <span v-if="product.precio">Precio: {{ formatCurrency(product.precio) }}</span>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
    </div>
    <Pagina4 id="catalogo"></Pagina4>
    <Pagina5
        :colaciones="colaciones"
    ></Pagina5>
    <Pagina6
        :canastas="canastas"
    ></Pagina6>
    <Footer></Footer>
    
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/swiper-bundle.css';
import logoInicio from "@/assets/inicio_logo.png";
import esquinaConfitel from "@/assets/esquinaConfitel.png";
import ofertas from "@/assets/oferta.jpg";
import wsp from "@/assets/wsp.png";
import fondo from "@/assets/fondo.png";
//import { MagnifyingGlassIcon} from '@heroicons/vue/24/solid';
import Footer from "@/components/Home/Footer.vue";
import Pagina4 from "@/components/Home/Pagina4.vue";
import Pagina5 from "@/components/Home/Pagina5.vue";
import Pagina6 from "@/components/Home/Pagina6.vue";
import Swal from "sweetalert2";
import NavBar from '@/components/Navbar.vue'
export default {
    name: "HomeConfitel",
    el: "#app",
    components: {
        //MagnifyingGlassIcon,
        Swiper,
        SwiperSlide,
        Pagina4,
        Pagina5,
        Pagina6,
        Footer,
        NavBar
    },
    mounted(){
        window.select_funcion = this.select_funcion;
        window.load_axios('/get_productos',1);
        window.load_axios('/get_colaciones_home',2);
        window.load_axios('/get_canastas_home',3);
        window.load_axios('chequeo_sesion',4);
        Swal.fire({
            title: '¡Bienvenido a Confitel!',
            text: 'Sitio en construcción...',
            icon: 'info',
            confirmButtonText: 'Aceptar'
        })
    },
    data() {
        return {
            admin: false,
            user: null,
            fondo: fondo,
            colaciones: {},
            canastas: {},
            wsp: wsp,
            ofertas: ofertas,
            logoInicio: logoInicio,
            esquinaConfitel: esquinaConfitel,
            menuOpen: false,
            products: [],
            productsIncorporacion: [],
            modules: [Autoplay, Pagination],
            swiperOptions: {                
                slidesPerView: 1,
                spaceBetween: 30,
                pagination: {
                el: '.swiper-pagination',
                clickable: true
                },
                breakpoints: {
                640: {
                    slidesPerView: 1,
                    spaceBetween: 20
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 40
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 50
                }
                }
            }
        };
    },
    methods: {
        redirectContacto() {
            window.location.href = "/contacto";
        },
        formatCurrency(value) {
            return new Intl.NumberFormat('es-CL', {
                style: 'currency',
                currency: 'CLP'
            }).format(value);
        },
        handleAddToCart(product) {
            if (product.price){
                window.addToCart(product);    
            }
        },
        scrollToSection(sectionId) {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        },
        toggleMenu() {
            this.menuOpen = !this.menuOpen;
        },
        select_funcion(response, numero_funcion){
            switch(numero_funcion){
                case 1:
                    this.products = response.data.productos;
                    this.productsIncorporacion = response.data.productsIncorporacion;
                    break;
                case 2:
                    this.colaciones = response.data.colaciones;
                    break;
                case 3:
                    this.canastas = response.data.canastas;
                    break;
                case 4:
                    this.user = response.data['user'];
                    break;
            }
        },
    },
}
</script>
<style>
/* En tu archivo CSS */
.background-home {
  background-image: url('@/assets/espacio.jpg');
  background-size: cover; /* Ajusta la imagen para cubrir todo el fondo */
  background-position: center; /* Centra la imagen */
  background-repeat: no-repeat;
}
</style>