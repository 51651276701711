<template>
  <div class="container mx-auto p-4">
    <h2 class="text-2xl font-bold mb-4">Carrito de Compras</h2>
    <div v-if="cartItems.length === 0" class="text-gray-500">Tu carrito está vacío</div>
    <ul>
      <li v-for="item in cartItems" :key="item.id" class="flex items-center border-b py-4">
        <img v-if="isValidImage(item.img)" :src="'static' + item.img" alt="item.name" class="w-16 h-16 object-cover mr-4"/>
        <img v-else :src="'static/productos/' + item.img" alt="item.name" class="w-16 h-16 object-cover mr-4"/>
        <!-- static/productos/ -->
        <div class="flex-grow">
          <span class="font-semibold">{{ item.name }}</span>
          <span class="ml-2 text-gray-600">{{ formatCurrency(item.price) }}</span>
          <span class="ml-2 text-gray-600">x {{ item.quantity }}</span>
        </div>
        <button @click="removeFromCart(item)" class="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700">Eliminar</button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['cartItems'],
  computed: {
    cartTotal() {
      return this.cartItems.reduce((total, item) => {
        return total + item.price * item.quantity;
      }, 0);
    }
  },
  methods: {
    isValidImage(imgPath) {
      const img = new Image();
      img.src = 'static' + imgPath;
      return img.complete && img.naturalHeight !== 0;
    },
    removeFromCart(item) {
      this.$emit('remove-from-cart', item);
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('es-CL', {
        style: 'currency',
        currency: 'CLP'
      }).format(value);
    }
  }
}
</script>