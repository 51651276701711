<template>
    <div class="flex flex-row space-x-10 bg-[#313131] justify-center py-6">
        <div class="flex flex-col">
            <div class="flex flex-row space-x-10 items-center text-white text-start">
                <div class="flex flex-col">
                    <div class="font-bold text-2xl text-center">Acerca</div>
                    <div>Mision y visión</div>
                    <div>Contáctanos</div>
                    <div>Política de privacidad</div>
                    <div>Términos de uso</div>
                    <div>Legal</div>
                </div>
                <div class="flex flex-col">
                    <div class="font-bold text-2xl text-center">Servicios</div>
                    <div>Cómo realizar una compra</div>
                    <div>Nuestros productos</div>
                    <div>Estado del pedido</div>
                    <div>Promociones</div>
                    <div>Métodos de pago</div>
                </div>
            </div>
            <div class="flex flex-row justify-center mt-6">
                <div class="flex w-[150px] h-[40px] bg-yellow-400 text-white items-center justify-center">Regístrate Aqui</div>
                <div class="w-[50px] h-[40px] bg-teal-400"></div>
            </div>
        </div>
        <div class="flex flex-col">
            <img :src="celular" :alt="'celu'" class="w-[200px]"/>
        </div>
        <div class="py-4"></div>
    </div>
</template>
<script>
export default {
    name: "FooterHome",
    data() {
        return {
            celular: require("@/assets/celu.png"),
        };
    },
}
</script>